@import "~core/abstract/all";
@import "~bootstrap/scss/mixins";

.mobile-side-scroll {
    padding: 0 10px;
    overflow: auto;
    .row {
        flex-wrap: nowrap;
        margin: auto;
    }
    img {
        width: 250px;
        max-width: none;
    }
    .mobile-side-scroll-item {
        padding: 12px;
    }
    @include media-breakpoint-up(sm) {
        img {
            width: 100%;
            max-width: 100%;
        }
    }
    @include media-breakpoint-up(ls) {
        overflow: visible;
    }
    .content{
        @include media-breakpoint-up(sm) {
            justify-content: center;
        }
    }
}